.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 40px;
    border-radius: 16px;
    width: 90%;
    max-width: 800px;
    max-height: 85vh;
    overflow-y: auto;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eee;
}

.modal-header h2 {
    font-size: 24px;
    line-height: 1.2;
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
}

.warehouse-form {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.form-group {
    margin-bottom: 0;
    /* Reset margin since we're using gap */
}

.form-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 28px;
    margin-bottom: 0;
    /* Reset margin since we're using gap */
}

.form-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    color: #374151;
    font-size: 0.95rem;
}

.form-group input {
    width: 95%;
    padding: 14px 16px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 0.95rem;
    transition: all 0.2s;
}

/* .form-group input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
} */

.form-group input::placeholder {
    color: #9ca3af;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 10px;
}

.form-actions button {
    padding: 12px 28px;
    border-radius: 8px;
    font-size: 0.95rem;
    font-weight: 500;
    transition: all 0.2s;
}

.form-actions button:hover {
    transform: translateY(-1px);
}

.form-actions button[type="submit"] {
    background-color: #3b82f6;
    color: white;
    border: none;
}

.form-actions button[type="submit"]:hover {
    background-color: #2563eb;
    box-shadow: 0 4px 6px rgba(37, 99, 235, 0.1);
}

.form-actions button[type="button"] {
    background-color: #f3f4f6;
    color: #4b5563;
    border: none;
}

.form-actions button[type="button"]:hover {
    background-color: #e5e7eb;
}

.action-buttons {
    display: flex;
    gap: 12px;
}

.edit-btn,
.delete-btn {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 16px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
}

.edit-btn {
    background-color: #f3f4f6;
    color: #1f2937;
    border: 1px solid #e5e7eb;
}

.edit-btn:hover {
    background-color: #e5e7eb;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.delete-btn {
    background-color: #fee2e2;
    color: #dc2626;
    border: 1px solid #fecaca;
}

.delete-btn:hover {
    background-color: #fecaca;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(220, 38, 38, 0.1);
}

.edit-btn svg,
.delete-btn svg {
    transition: transform 0.2s ease;
}

.edit-btn:hover svg,
.delete-btn:hover svg {
    transform: scale(1.1);
}

/* Optional: Add responsive styles for mobile */
@media (max-width: 768px) {

    .edit-btn span,
    .delete-btn span {
        display: none;
    }

    .edit-btn,
    .delete-btn {
        padding: 8px;
    }
}

/* Phone Input Styles */
.phone-input {
    width: 100% !important;
    padding: 14px 16px !important;
    border: 1px solid #e5e7eb !important;
    border-radius: 8px !important;
    font-size: 0.95rem !important;
    transition: all 0.2s !important;
}

.phone-input:focus-within {
    border-color: #3b82f6 !important;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1) !important;
}

.PhoneInputCountry {
    margin-right: 10px;
}

.PhoneInputCountrySelect {
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #e5e7eb;
}

.PhoneInputInput {
    border: none !important;
    padding: 0 !important;
    outline: none !important;
    width: 100% !important;
}

.PhoneInputInput:focus {
    outline: none !important;
}

/* Fix for country select padding */
.PhoneInputCountrySelectArrow {
    margin-left: 8px;
}