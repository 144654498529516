/* General App Styles */
.app {
  display: flex;
  height: 95vh;
  overflow: hidden;
  padding: 15px;
}

body {
  background-color: #1b1b1b;
}


/* Sidebar Styles */
.sidebar {
  background-color: #fff;
  width: 200px;
  padding: 30px 10px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  flex-direction: column;
  justify-content: flex-start;
}

.lynkd-logo-sidebar {
  font-family: 'Montserrat';
  font-size: 2rem;
  text-align: left;
  margin-bottom: 25px;
}

.lynkd-logo {
  font-family: sans-serif;
  font-size: 12rem;
  font-weight: bold;
  display: inline-block;
  /* background: linear-gradient(90deg, rgb(19, 48, 122) 50%, rgb(74, 22, 134) 100%); */
  background: #ece9df;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2),
  -3px -3px 0px rgba(0, 0, 0, 0.2); */
  /* White glow on the right-bottom */
  /* White glow on the left-top */
  letter-spacing: 20px;
}


.lynkd-logo-smaller {
  color: rgb(255, 255, 255);
  font-family: sans-serif;
  font-size: 4.5em;
  font-weight: bold;
  display: inline-block;
  /* text-shadow: 0 0 100px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.8); */
}


.sidebar-menu {
  margin-top: 0;
  /* Remove margin to avoid unnecessary spacing */
  height: 100%;
  position: relative;
}


.sidebar-logo {
  font-size: 1.75rem;
  font-weight: bold;
  padding: 20px 0;
  color: #f57c00;
  /* Highlighted color for branding */
}

.sidebar-menu a {
  display: flex;
  align-items: center;
  padding: 8px 0px;
  font-size: 1rem;
  color: #505050;
  max-width: 160px;
  text-decoration: none;
  border-radius: 10px;
  margin-bottom: 10px;
  /* shadow */
  /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 3px #fff; */
  transition: all 0.5s ease;
}

.sidebar-menu a span {
  text-align: left;
  width: 100%;
  margin-left: 10px;
}

.sidebar-menu a svg {
  text-align: center;
  margin-left: 10px;
}

.sidebar-menu a.active {
  background-color: rgb(245, 244, 244);
  color: black;
  box-shadow: none;
}

.sidebar-menu a:hover {
  box-shadow: inset 0px 0px 0px 1px #000;
}

.sidebar-menu a .icon {
  margin-right: 15px;
  font-size: 1.2em;
}

.logout-btn {
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  border: none;
  /* box-shadow: 0px 4px 12px rgba(161, 3, 3, 0.386), inset 0px 0px 0px 3px #fff; */
  margin-top: 20px;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.logout-btn:hover {
  background-color: #f9cdcd;
  box-shadow: inset 0px 0px 0px 1px #a20404 !important;
}

.badge {
  background: red;
  color: white;
  padding: 4px 4px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 20px;
  margin-left: auto;
  position: absolute;
  right: 10px
}

/* Dashboard Styles */
.dashboard,
.shop,
.add-product,
.messages,
.warehouses,
.create-campaign {
  background: #f9fafc;
  width: 90%;
  padding: 30px;
  overflow-y: scroll;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.header h2 {
  font-size: 24px;
  color: #333;
}

.header span {
  color: #7a7a7a;
  font-size: 0.9rem;
  margin-left: 5px;
}

.actions .new-order-btn {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  border: none;
}

.actions .new-order-btn:hover {
  background-color: #333;
}

/* StatsCard Styles */
.dashboard-summary {
  display: flex;
  gap: 20px;
}

.stats-card {
  background-color: #ffffff;
  border-radius: 12px;
  /* padding: 10px; */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.5s ease;
}

.stats-card:hover,
.stats-card:hover p,
.stats-card:hover h3 {
  /* background-color: #1b1b1b; */
  color: white;
}

.stats-card:hover {
  background-color: #1b1b1b;
}



.stats-card .icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.stats-card h3 {
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  font-weight: normal;
  margin: 0;
  margin-top: 15px;
  margin-left: 20px;
}

.stats-card p {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: left;
  color: #505050;
  margin: 0;
  margin-left: 20px;
}

/* PackageInfo Styles */
.package-info {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-right: 20px;
  flex-grow: 2;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.package-info h3 {
  font-size: 1.2rem;
  color: #505050;
  margin-bottom: 10px;
}

.package-info .status {
  color: #f57c00;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.package-details {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.package-details div {
  background-color: #f3f4f7;
  border-radius: 5px;
  padding: 10px;
  flex-grow: 1;
  text-align: center;
}

.recipient {
  margin-top: 20px;
}

.recipient h4 {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Map Styles */
.map-overview {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  flex-grow: 3;
  height: 300px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.map-overview h3 {
  margin-bottom: 15px;
}

.map {
  width: 100%;
  height: calc(100% - 45px);
  /* Take into account header */
  background-color: #e5e5e5;
  /* Placeholder background */
}

/* Speed Statistics Styles */
.speed-statistics {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  flex: 1;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.speed-statistics h3 {
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.speed-gauge {
  height: 150px;
  background-color: #f57c00;
  border-radius: 50%;
  width: 150px;
  margin: 0 auto;
}

.speed-statistics strong {
  font-size: 2rem;
  color: #505050;
  margin-top: 20px;
  display: block;
}

/* Transportations Styles */
.transportations {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.transportations h3 {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.transportations .vehicle-image img {
  width: 80%;
  height: auto;
  margin-bottom: 10px;
}

.transport-info {
  font-size: 0.9rem;
  color: #505050;
  text-align: center;
}

.transport-info div {
  margin-bottom: 5px;
}

/* General Layout Styles */
.dashboard-details {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.dashboard-stats {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.popular-products-container {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 0 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.popular-products-container h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.popular-products-card {
  position: relative;
  width: 150px;
}


.popular-products-card img {
  width: 150px;
  height: 180px;
  border-radius: 12px;
  object-fit: cover;
}

.product-details {
  position: absolute;
  bottom: 4px;
  background-color: rgba(229, 226, 226, 0.7);
  backdrop-filter: blur(5px);
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  text-align: center;
}

.product-details h3 {
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
}

.popular-products-title {
  font-size: 1.5rem;
  color: #333;
  margin: 20px 0px;
}

.product-cards-container {
  width: 100%;
  align-self: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px auto;
  position: relative;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
  background-color: #1b1b1b;
  padding: 5px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  /* margin: -30px -40px; */
  position: absolute;
  top: 120px;
}

.swiper {
  width: 100%;
}

.swiper-button-next {
  right: -55px;
}

.swiper-button-prev {
  left: -75px;
}

.creators-stats {
  justify-content: space-between;
  display: flex;
  margin: 10px 20px;
}

.creators-stats>span {
  color: #000000;
  font-weight: 700;
  font-size: 0.9rem;
  margin-left: 5px;
}

.header-date {
  font-size: 1.3em !important;
}

.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: row;
}

.signin-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50vw;
  height: 90vh;
  background-color: #000;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  /* Add this to contain the blurred background */
}

.signin-left-glitch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  border-radius: 50px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}


.signin-right {
  display: flex;
  flex-direction: column;
  width: 50vw;
  justify-content: center;
  align-items: center;
}


/* sign in */
.sign-in-container {
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 30vw;
}

.sign-in-label {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
}

.sign-in-title {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.sign-in-input {
  width: 100%;
  padding: 20px;
  margin-bottom: 25px;
  background-color: transparent;
  border: 1px solid #444;
  border-radius: 15px;
  font-size: 1.4em;
  color: #ffffff;
  margin-top: 15px;
  box-sizing: border-box;
  box-shadow: 10px 5px 4px 0px rgba(255, 255, 255, 0.25), nones;
  transition: all 1s ease;
}

.sign-in-input:hover {
  background-color: #444;
  box-shadow: inset 0px 0px 0px 3px #fff;
}

.sign-in-button {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  font-size: 1.4em;
  border-radius: 100px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.25), none;
  transition: all 1s ease;
}

.sign-in-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.sign-in-line {
  flex: 1;
  height: 1px;
  background-color: #444;
}

.sign-in-or-text {
  margin: 0 10px;
  color: #888;
}

.sign-in-social-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sign-in-social-button {
  flex: 1;
  padding: 20px;
  background-color: #333;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  font-size: 1.4em;
  align-items: center;
  justify-content: space-between;
  transition: all 1s ease;
}

.sign-in-button:hover {
  background-color: rgb(230, 230, 224);
  box-shadow: inset 0px 0px 0px 3px #000dff;
}

.sign-in-social-button:hover {
  background-color: #444;
  box-shadow: inset 0px 0px 0px 3px #fff;
}

.sign-in-social-button:last-child {
  margin-right: 0;
}

.sign-in-social-icon {
  margin-right: 5px;
}

.sign-in-signup-text {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #888;
}

.sign-in-signup-button {
  background-color: transparent;
  border: 1px solid #444;
  color: #ffffff;
  padding: 20px;
  border-radius: 100px;
  cursor: pointer;
  width: 100%;
  font-size: 1.4em;
  margin-top: 10px;
  transition: all 1s ease;
}

.sign-in-signup-button:hover {
  background-color: #444;
  box-shadow: inset 0px 0px 0px 3px #fff;
}

/* Get to Know  */

.know-more-container {
  display: flex;
  height: 95vh;
  flex-direction: row;
}

.know-more-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 15vw;
  height: 100%;
  /* background-image: url('./assets/signinbg.jpg'); */
  /* background: linear-gradient(90deg, rgba(12, 28, 67, 1) 50%, rgb(35, 11, 63) 100%); */
  background-color: #000;
  background-size: cover;
  border-radius: 25px;
  /* box-shadow: 5px 8px 4px 0px rgba(255, 255, 255, 0.25); */
  position: relative;
}

.know-more-left-glitch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  border-radius: 50px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}


.know-more-right {
  display: flex;
  flex-direction: column;
  width: 82vw;
  border: #1b1b1b 2px solid;
  background-color: #f4f3f3;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  /* padding-top: 70vh; */
  margin-left: 20px;
  overflow-y: scroll;
}

.knowMore-container {
  background-color: #f4f3f3;
  color: #000000;
  border-radius: 10px;
  width: 80%;
  padding-left: 40px;
}

.know-more-label {
  font-size: 1.2rem;
  color: #1b1b1b;
  font-weight: 600;
}

.know-more-title {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.know-more-subtitle {
  font-size: 1.3em;
  margin-top: -10px;
  margin-bottom: 20px;
}

.download-template-button {
  background-color: #c9c9c9;
  color: #1b1b1b;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2em;
  border: none;
  height: 50px;
  align-self: center;
  transition: all 1s ease;
}

.download-template-button:hover {
  background-color: #444;
  box-shadow: inset 0px 0px 0px 3px #fff;
  color: #ffffff;
}


.know-more-input {
  /* width: 100%; */
  padding: 10px;
  margin-bottom: 25px;
  background-color: transparent;
  border: 1px solid #444;
  border-radius: 15px;
  font-size: 1em;
  color: #1b1b1b;
  margin-top: 15px;
  box-sizing: border-box;
  box-shadow: 10px 5px 4px 0px rgba(255, 255, 255, 0.25), nones;
  transition: all 1s ease;
  align-self: center;
  height: 50px;
}

.know-more-input:focus {
  outline: none;
  /* Remove default focus outline */
  /* background-color: #ccc; */
  /* Change background on focus */
  /* box-shadow: inset 0px 0px 0px 3px #fff; */
  /* Retain the desired focus shadow */
}


.know-more-input:hover {
  /* background-color: #ccc; */
  /* box-shadow: inset 0px 0px 0px 3px #fff; */
}

/* disable hover for file type know-more-input */
.know-more-input[type="file"]:hover {
  background-color: transparent;
  box-shadow: none;
}

.know-more-button {
  width: 80%;
  padding: 20px;
  background-color: #1b1b1b;
  color: #000000;
  border: none;
  font-size: 1.4em;
  border-radius: 100px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.25), none;
  transition: all 1s ease;
  margin: 20px 0;
}

.know-more-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.know-more-line {
  flex: 1;
  height: 1px;
  background-color: #444;
}

.know-more-or-text {
  margin: 0 10px;
  color: #888;
}

.know-more-social-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.disabled {
  background-color: #adadad;
  color: #000;
  cursor: not-allowed;
}

.know-more-social-button {
  flex: 1;
  padding: 10px;
  background-color: #333;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  font-size: 1.2em;
  width: 30%;
  align-items: center;
  justify-content: space-between;
  transition: all 1s ease;
  margin-bottom: 20px;
}

.know-more-button:hover {
  background-color: rgb(230, 230, 224);
  box-shadow: inset 0px 0px 0px 3px #000dff;
}

.know-more-social-button:hover {
  background-color: #444;
  box-shadow: inset 0px 0px 0px 3px #fff;
}

.know-more-social-button:last-child {
  margin-right: 0;
}

.know-more-social-icon {
  align-self: center;
  margin-bottom: 10px;
}

.know-more-signup-text {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #888;
}

.know-more-signup-button {
  background-color: transparent;
  border: 1px solid #444;
  color: #ffffff;
  padding: 20px;
  border-radius: 100px;
  cursor: pointer;
  width: 100%;
  font-size: 1.4em;
  margin-top: 10px;
  transition: all 1s ease;
}

.know-more-signup-button:hover {
  background-color: #444;
  box-shadow: inset 0px 0px 0px 3px #fff;
}

.form-container {
  width: 80%;
  margin: 0 auto;
}

input[type="file"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

button:disabled {
  opacity: 0.5;
}

.verified {
  margin-top: 20px;
  color: green;
}

.not-verified {
  margin-top: 20px;
  color: red;
}

.document-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.form-flex {
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 100px;
}

.check-verification-btn {
  background-color: #fff;
  color: #000;
  cursor: pointer;
  padding: 10px 20px;
  display: block;
  width: 20%;
  height: 70%;
  margin: 5px 0px;
  border-radius: 10px;
  transition: all 0.5s ease;
}

.check-verification-btn:hover {
  background-color: #000;
  color: #fff;
}

.phone-number-input {
  width: 40%;
}

.form-control {
  display: flex;
  flex-direction: column;
}

.seperate-section {
  background-color: #fefefe;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

/* brand logo */
.brand-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.logo-preview {
  width: 150px;
  height: 150px;
  border: 2px solid #999;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  /* Grey background color for default */
  position: relative;
}

.logo-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures that the image covers the container without distortion */
  border-radius: 10px;
  /* Keeps the rounded corners consistent on the uploaded image */
}

.default-square {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d4d2d2;
  border: dashed 2px #999;
  /* Default grey color matching your placeholder */
  border-radius: 10px;
}

.upload-icon {
  color: #ccc;
}

.upload-label {
  margin-top: 10px;
  color: #1b1b1b;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.preview-section {
  margin-bottom: 30px;
}

.preview-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.preview-item span {
  margin-left: 10px;
  font-size: 16px;
}

.save-draft-button {
  background-color: #1b1b1b;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  margin: 10px 0;
  width: 60%;
  gap: 10px;
  align-items: center;
  display: flex;
}

.full-screen-onboarding {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
  width: 100vw;
  /* background-image: url('./assets/signinbg.jpg'); */
  background: linear-gradient(90deg, rgba(12, 28, 67, 1) 50%, rgb(35, 11, 63) 100%);
  background-size: cover;
  border-radius: 25px;
  box-shadow: 5px 8px 4px 0px rgba(255, 255, 255, 0.25);
}

.onboarding-waiting {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  border-radius: 50px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.onboarding-first {
  color: #fff;
  font-size: 3em;
  width: 70%;
  text-align: center;
}

.onboarding-second {
  color: #fff;
  font-size: 2em;
  width: 70%;
  text-align: center;
}

.onboarding-third {
  color: #fff;
  font-size: 1.5em;
  width: 70%;
  text-align: center;
}

.onboarding-visit-landing {
  background-color: #fff;
  color: #000;
  cursor: pointer;
  padding: 20px 20px;
  display: block;
  width: 20%;
  font-size: 1.5em;
  border-radius: 5px;
  margin-top: 20px;
}


/* SHOP PAGE */
.shop-header {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 5px 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  /* width: 95%; */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

/* Container to hold all elements */
.shop-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Search bar styling */
.shop-search {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 30px;
  width: 20vw;
  margin-left: 20px;
  border: 1px solid #ddd;
}

.shop-search i {
  color: #aaa;
  margin-right: 10px;
}

.shop-search input {
  border: none;
  outline: none;
  width: 100%;
  background: none;
  font-size: 16px;
}

/* Filter button styling */
.filter-button {
  margin-left: 15px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
  background: none;
  border: 2px solid #000;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.filter-button:hover {
  background-color: #000;
  color: white;
}

/* Add Product button styling */
.add-product-button {
  margin-left: 10px;
  padding: 16px 30px;
  font-size: 16px;
  border-radius: 10px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
}

.add-product-button:hover {
  background-color: #505050;
}

/* Table container styling */
.table-container {
  /* width: 75vw; */
  overflow-x: auto;
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Product table styling */
.product-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

/* Table header */
.product-table thead th {
  text-align: left;
  padding: 10px;
  border-bottom: 2px solid #ddd;
  font-weight: 700;
  font-size: 1.25em;
}

/* Table rows */
.product-table tbody tr {
  border-bottom: 1px solid #ddd;
}

.product-table tbody tr td {
  padding: 10px;
  vertical-align: middle;
}

/* Product image styling */
.product-table tbody tr td img {
  /* width: 40px;
  height: 40px; */
  margin-right: 10px;
  border-radius: 8px;
  vertical-align: middle;
}

.edit-btn:hover {
  background-color: #505050;
  color: white;
}

/* Pagination */
.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination span {
  font-weight: bold;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.pagination-controls span {
  margin: 0 10px;
}

.product-table-category {
  align-items: center;
  font-size: 1.2em;
}

.product-table-price {
  font-size: 1.2em;
}

.product-table-inventory {
  font-size: 1.2em;
}

.product-table-edit-btn,
.product-table-view-btn {
  font-size: 1.2em;
  background-color: #fff;
  border-radius: 20px;
  padding: 8px 20px;
  color: #000000;
  border: 2px solid #000;
  width: 130px;
  margin-right: 20px;
  transition: all 0.5s ease;
  cursor: pointer;
}

.product-table-view-btn {
  border: 2px solid #151515;
}

.status-action {
  background-color: #000;
  color: #fff;
  width: 180px;
  border: none;
}

.product-table-edit-btn:hover {
  background-color: #505050;
  color: white;
}

.product-table-view-btn:hover {
  background-color: #151515;
  color: white;
}

.product-table-product-name {
  font-size: 1.2em;
}

.product-table-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prev-btn,
.next-btn {
  background-color: #edebeb;
  padding: 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  margin: 0 10px;
}

.products-per-page {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.products-per-page label {
  font-size: 16px;
  font-weight: 500;
}

.products-per-page select {
  padding: 10px;
  ;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 20px;
  background-color: white;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.products-per-page select:hover {
  border-color: #1e90ff;
}

.products-per-page select:focus {
  outline: none;
  border-color: #0056b3;
}

/* ADD PRODUCT PAGE */

.add-product-header {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 5px 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  /* width: 95%; */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.bulk-update-button {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 15px 30px;
  border-radius: 12px;
  font-size: 0.9rem;
  cursor: pointer;
  border: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 2px #000000;
  margin: 10px;
  align-self: center;
  transition: all 0.5s ease;
}

.bulk-update-button:hover {
  background-color: #1b1b1b;
  color: white;
  box-shadow: inset 0px 0px 0px 3px #000000 !important;
}

.save-product-button {
  background-color: #000;
  color: white;
  padding: 15px 30px;
  border-radius: 12px;
  font-size: 0.9rem;
  cursor: pointer;
  border: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 2px #000000;
  margin: 10px;
  align-self: center;
  transition: all 0.5s ease;
}

.save-product-button:hover {
  background-color: #0d6e2c;
  box-shadow: inset 0px 0px 0px 3px #000000 !important;
}

.product-form {
  column-count: 2;
  /* Adjust the number of columns as needed */
  column-gap: 20px;
  /* Space between columns */
  padding: 20px 0;
  background-color: #f8f9fa;
}

.form-section {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  break-inside: avoid;
  /* Prevent sections from breaking across columns */
  margin-bottom: 20px;
  /* Space between sections */
}

.form-section h3 {
  margin-bottom: 15px;
  font-size: 20px;
}

.basic-info input,
.basic-info textarea,
.price-section input,
.category-section select,
.add-category input,
.affiliations input {
  width: 95%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  /* box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); */
}

.price-section input {
  width: 25%;
  margin-right: 5px;
}

.price-section label {
  width: 40%;
  display: inline-block;
  margin: 0 10px;
}

.add-category input,
.add-category-select {
  width: 50%;
  margin-right: 30px;
}

.category-section {
  display: flex;
}

.basic-info textarea {
  height: 100px;
}

.product-images .image-upload {
  display: flex;
  flex-direction: row;
}

.gallery-images,
.main-image {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 60%;
}

.image-urls-area {
  height: 120px !important;
}

.main-image {
  width: 30%;
}

.image-upload,
.gallery-images {
  display: flex;
  align-items: center;
  gap: 10px;
}

.image-placeholder,
.image-placeholder-main {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 10px;
  cursor: pointer;
}

.image-placeholder-main {
  width: 170px;
  height: 170px;
}

.add-category {
  width: 100%;
  display: flex;
}

.add-category button {
  padding: 10px;
  background-color: #ffffff;
  border: none;
  height: 80%;
  align-self: center;
  margin: 0 10px;
  color: black;
  border: solid 3px #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.add-category button:hover {
  background-color: #000000;
  color: #FFF;
}

.affiliations {
  display: flex;
  flex-direction: column;
}

.affiliations .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #000;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.product-form label {
  font-size: 0.9em;
  font-weight: 700;
}


.image-upload-label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Search bar styling */
.product-input {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 10px;
  /* width: 10vw; */
  height: 3vh;
  border: 1px solid #ddd;
  margin: 10px 0px;
}

.product-select {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 10px;
  /* width: 10vw; */
  width: 40%;
  height: 3vh;
  border: 1px solid #ddd;
  margin: 10px 0px;
}

.product-textarea {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 10px;
  /* width: 10vw; */
  height: 20vh;
  border: 1px solid #ddd;
  margin: 10px 0px;
  overflow: hidden;
}


.product-input input,
.product-textarea textarea,
.product-select select {
  border: none;
  outline: none;
  width: 100%;
  height: 90%;
  background: none;
  font-size: 13px;
  box-shadow: none;
  resize: none;
}

/* digital product toggle */
.switch2 {
  position: relative;
  display: inline-block;
  width: 50px;
  /* Slightly larger for visibility */
  height: 25px;
}

.switch2 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider2 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: background-color 0.4s ease;
  /* Smooth animation for color change */
  border-radius: 20px;
}

.slider2:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  /* Matches padding inside the switch */
  bottom: 3px;
  background-color: white;
  transition: transform 0.4s ease;
  /* Smooth animation for sliding */
  border-radius: 50%;
}

input:checked+.slider2 {
  background-color: #000;
  /* Changes the background color */
}

input:checked+.slider2:before {
  transform: translateX(26px);
  /* Moves the knob */
}


/* ORDERS PAGE */

.orders-profile-picture {
  margin-right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
}

/* Dropdown styling */
#orderStatusFilter {
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 10px;
  font-size: 16px;
  border: 2px solid #000;
  background: none;
  color: #000;
  cursor: pointer;
  outline: none;
}

#orderStatusFilter:hover {
  background-color: #f0f0f0;
  color: #505050;
}

#orderStatusFilter option {
  background-color: white;
  color: black;
}

.filter-section {
  display: flex;
  margin-left: 20px;
}

.filter-section .filter-button {
  margin: auto;
}

/* ADS Management */

.toggle-switch {
  display: flex;
  align-items: center;
  margin: 10px;
}

.toggle-switch input {
  display: none;
}

.toggle-switch label {
  display: block;
  width: 60px;
  height: 34px;
  background-color: #ccc;
  border-radius: 34px;
  position: relative;
  cursor: pointer;
}

.toggle-switch label .slider {
  position: absolute;
  cursor: pointer;
  top: 4px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  transition: .4s;
}

.toggle-switch input:checked+label {
  background-color: #000;
  /* Green color when checked */
}

.toggle-switch input:checked+label .slider {
  transform: translateX(26px);
}

.toggle-switch label .slider:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .4s;
}

.ad-table tbody tr td::content {
  width: 200px;
}

.product-selection {
  margin-bottom: 20px;
}

.search-product,
.link-product {
  margin-bottom: 15px;
}

.search-label {
  display: flex;
  align-items: center;
}

.search-label input {
  flex: 1;
  padding: 10px;
  margin-left: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.search-label button {
  margin-left: 10px;
  padding: 10px;
  border: none;
  background-color: #0F27BD;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.link-product input {
  width: 80%;
  padding: 10px;
  margin-top: 5px;
  margin-right: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.link-product button {
  margin-top: 10px;
  padding: 10px;
  border: none;
  background-color: #0F27BD;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.selected-product {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.ad-frequency {
  margin-bottom: 20px;
}

.frequency-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.frequency-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}

.frequency-option input[type="radio"] {
  display: none;
}

.frequency-option .checkmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #0F27BD;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}

.frequency-option input[type="radio"]:checked+.checkmark {
  background-color: #0F27BD;
}

.frequency-option .checkmark::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 4px;
  left: 4px;
}

/* WalletCheck.css */
.wallet-check {
  margin-bottom: 20px;
}

.wallet-card {
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.wallet-info {
  margin-bottom: 15px;
}

.wallet-label {
  font-size: 16px;
  color: #555;
  margin-bottom: 5px;
}

.wallet-amount {
  font-size: 24px;
  font-weight: bold;
  color: #0F27BD;
}

.check-wallet-button {
  background-color: #7786e7;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.topup-wallet-button {
  background-color: #ffffff;
  color: #1b1b1b;
  border: solid 2px #1b1b1b;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.check-wallet-button:hover {
  background-color: #0F27BD;
  color: #FFF;
}

.topup-wallet-button:hover {
  background-color: #1b1b1b;
  color: #ffffff;
}

.check-wallet-button:focus {
  outline: none;
}

/* Add this to your CSS file or a <style> block */
.slider-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

.custom-slider {
  -webkit-appearance: none;
  /* Remove default styling in Webkit browsers */
  width: 100%;
  height: 8px;
  /* Slider height */
  background: #ddd;
  /* Track color */
  border-radius: 5px;
  /* Rounded corners for track */
  outline: none;
  /* Remove default outline */
  opacity: 0.7;
  /* Slight transparency */
  transition: opacity .2s;
  /* Smooth transition */
}

.custom-slider:hover {
  opacity: 1;
  /* Full opacity on hover */
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Remove default styling */
  appearance: none;
  /* Remove default styling */
  width: 24px;
  /* Thumb width */
  height: 24px;
  /* Thumb height */
  background: #0F27BD;
  /* Thumb color */
  border-radius: 50%;
  /* Round shape */
  cursor: pointer;
  /* Pointer cursor */
  transition: background .3s;
  /* Smooth transition */
}

.custom-slider::-webkit-slider-thumb:hover {
  background: #0056b3;
  /* Darker color on hover */
}

.custom-slider::-moz-range-thumb {
  width: 24px;
  /* Thumb width */
  height: 24px;
  /* Thumb height */
  background: #0F27BD;
  /* Thumb color */
  border-radius: 50%;
  /* Round shape */
  cursor: pointer;
  /* Pointer cursor */
  transition: background .3s;
  /* Smooth transition */
}

.custom-slider::-moz-range-thumb:hover {
  background: #0056b3;
  /* Darker color on hover */
}

.slider-value {
  font-size: 16px;
  font-weight: bold;
  color: #0F27BD;
  /* Matching color for text */
  width: 100px;
  /* Fixed width to align with the slider */
}

/* Brand Ambassadors */
/* Modal overlay to cover the entire screen */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above other content */
}


/* Modal content with animation */
.modal-content {
  background-color: white;
  padding: 25px;
  width: 700px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transform: translateY(-20px);
  /* Start position: slightly above */
  opacity: 0;
  /* Start with hidden */
  animation: slideIn 0.3s forwards ease-out;
  /* Slide-in animation */
}

/* Animation keyframes for modal slide-in effect */
@keyframes slideIn {
  to {
    transform: translateY(0);
    /* End position: normal */
    opacity: 1;
    /* Fully visible */
  }
}

/* Modal actions (buttons) */
.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}