/* General Body Layout */
.messages {
    display: flex;
    background: #f9fafc;
    width: 100%;
    overflow: hidden;
}

/* Sidebar Styles */
.sidebar-chat-container {
    /* border-right: 1px solid #ddd; */
    display: flex;
    flex-direction: column;
    width: 30%;
}

.sidebar-header {
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.sidebar-header input {
    width: 90%;
    padding: 10px;
    border-radius: 20px;
    border: 2px solid #ccc;
}

.sidebar-chats {
    flex-grow: 1;
    width: 95%;
    overflow-y: auto;
}

.sidebar-chat {
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    padding: 12px;
    display: flex;
    align-items: center;
}

.sidebar-chat h3 {
    margin: 0;
    font-size: 13px;
}

.sidebar-chat p {
    margin: 0;
    color: gray;
    font-size: 11px;
}

.notification-badge {
    background-color: #c9c9cb;
    padding: 5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: 20px;
    align-self: center;
    cursor: pointer;
    color: black;
    transition: all 0.3s ease-in;
}

.notification-badge:hover {
    background-color: #0F27BD;
    color: white;
}

.profile-picture {
    border-radius: 50%;
    margin-right: 15px;
    width: 50px;
    height: 50px;
}

.sidebar-chat.active {
    background-color: #efefef;
}

/* Chat Window Styles */
.chat-window {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 20px;
    overflow: hidden;
}

/* Chat Header */
.chat-header {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
}

.chat-header h2 {
    margin: 0;
    flex-grow: 1;
}

.chat-header span {
    color: green;
}

/* Chat Body */
.chat-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
}

.message {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.sender {
    align-items: flex-end;
}

.receiver {
    align-items: flex-start;
}

.message-content {
    background-color: #e2edf9;
    padding: 10px;
    border-radius: 10px;
    max-width: 60%;
}

.sender .message-content {
    background-color: #000;
    color: #fff;
    border-top-right-radius: 0;
}

.receiver .message-content {
    border-top-left-radius: 0;
}

.message-time {
    margin-top: 5px;
    color: gray;
    font-size: 12px;
}

/* Chat Input */
.chat-input {
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
}

.chat-input input {
    width: 50vw;
    display: block;
    margin: auto;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
}

.chat-input button {
    background-color: #000;
    color: #fff;
    padding: 5px 20px;
    border-radius: 20px;
    margin: 0 15px;
    border: none;
    cursor: pointer;
}

.online-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
    position: absolute;
    bottom: 5px;
    right: 2px;
}