.step-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step-container {
    display: flex;
    /* Use flex to lay out circle and label in a row */
    align-items: center;
    /* Center align items vertically */
    position: relative;
    margin: 20px 0;
    width: 300px;
    /* Adjust spacing as needed */
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #E0E0E0;
    /* Inactive circle color */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.circle-active {
    background-color: #E0E7FF;
    /* Active circle background color */
}

.inner-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #E0E0E0;
    /* Inactive inner circle color */
}

.inner-circle-active {
    background-color: #0000FF;
    /* Active inner circle color */
}

.line {
    width: 4px;
    /* Change dimensions for vertical */
    height: 60px;
    /* Adjust line height */
    background-color: #E0E0E0;
    /* Inactive line color */
    position: absolute;
    left: 18px;
    /* Center the line under the circle */
    top: 24px;
    /* Align with circle */
}

.line-active {
    background-color: #0000FF;
    /* Active line color */
}

.label {
    font-size: 1.2em;
    color: #7D7D7D;
    /* Inactive label color */
    text-align: center;
    margin-left: 8px;
    /* Space between circle and label */
}

.label-active {
    color: #000000;
    /* Active label color */
}

.step-previous,
.step-next {
    cursor: pointer;
    display: inline-flex;
    color: #ffffff;
    font-size: 1.2em;
    /* margin: 20px 0; */
    background-color: #494949;
    padding: 5px 5px;
    border-radius: 4px;
    align-self: center;
    margin-right: 20px;
}

.divider {
    margin: 20px 0px;
    background-color: #7D7D7D;
    height: 5px;
}