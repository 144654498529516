.glitch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    overflow: hidden;
}

.glitch-text {
    color: rgb(0, 0, 0);
    font-family: sans-serif;
    font-size: 3rem;
    font-weight: bold;
    position: relative;
    display: inline-block;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

.lynkd-logo {
    font-family: sans-serif;
    font-size: 12rem;
    font-weight: bold;
    display: inline-block;
    /* background: linear-gradient(90deg, rgb(19, 48, 122) 50%, rgb(74, 22, 134) 100%); */
    background: #000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2),
    -3px -3px 0px rgba(0, 0, 0, 0.2); */
    /* White glow on the right-bottom */
    /* White glow on the left-top */
}


.lynkd-logo-smaller {
    color: rgb(255, 255, 255);
    font-family: sans-serif;
    font-size: 4.5em;
    font-weight: bold;
    position: relative;
    display: inline-block;
    /* text-shadow: 0 0 100px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.8); */
}

.glitch-text::before,
.glitch-text::after {
    content: "lynkd";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glitch-text::before {
    left: 2px;
    text-shadow: -2px 0 #ff00c1;
    clip: rect(24px, 550px, 90px, 0);
    /* animation: glitch-anim-1 1ms infinite linear alternate-reverse; */
}

.glitch-text::after {
    left: -2px;
    text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
    /* animation: glitch-anim-2 500ms infinite linear alternate-reverse; */
}

/* animated */

.glitch-text-animated {
    color: rgb(0, 0, 0);
    font-family: sans-serif;
    font-size: 12rem;
    font-weight: bold;
    position: relative;
    display: inline-block;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

.glitch-text-animated::before,
.glitch-text-animated::after {
    content: "lynkd";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glitch-text-animated::before {
    left: 2px;
    text-shadow: -2px 0 #ff00c1;
    clip: rect(24px, 550px, 90px, 0);
    animation: glitch-anim-1 1s infinite linear alternate-reverse;
}

.glitch-text-animated::after {
    left: -2px;
    text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
    animation: glitch-anim-2 1s infinite linear alternate-reverse;
}



@keyframes glitch-anim-1 {
    0% {
        clip: rect(86px, 9999px, 82px, 0);
    }

    20% {
        clip: rect(38px, 9999px, 135px, 0);
    }

    40% {
        clip: rect(81px, 9999px, 76px, 0);
    }

    60% {
        clip: rect(34px, 9999px, 133px, 0);
    }

    80% {
        clip: rect(91px, 9999px, 190px, 0);
    }

    100% {
        clip: rect(90px, 9999px, 266px, 0);
    }
}

@keyframes glitch-anim-2 {
    0% {
        clip: rect(15px, 9999px, 200px, 0);
    }

    20% {
        clip: rect(19px, 9999px, 134px, 0);
    }

    40% {
        clip: rect(6px, 9999px, 162px, 0);
    }

    60% {
        clip: rect(89px, 9999px, 76px, 0);
    }

    80% {
        clip: rect(82px, 9999px, 129px, 0);
    }

    100% {
        clip: rect(56px, 9999px, 198px, 0);
    }
}